<template>
  <div class="grey lighten-3" style="height:calc(100% - 50px);overflow:auto">
    <v-card class="ma-3" color="transparent" flat>
      <v-sheet
        color="primary"
        elevation="6"
        class="px-4 py-2 mt-8 mx-2 mb-2 v-card-material rounded"
        dark
      >
        <div class="v-card-material-title pt-1">
          问题管理
        </div>
        <div class="v-card-material-subtitle py-1">
          腹透远程系统
        </div>
      </v-sheet>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        grow
        class="pb-0 "
        active-class="primary white--text"
      >
        <v-tabs-slider color="transparent"></v-tabs-slider>
        <v-tab
          class="mx-2 mb-2 elevation-3 rounded white"
          v-for="item in items"
          :key="item"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="background-color: transparent">
        <v-tab-item>
          <v-card color="white" class="mx-2 mt-1 mb-2 elevation-3" flat>
            <v-overlay absolute :value="loading">
              <v-progress-circular indeterminate size="64" />
            </v-overlay>
            <div class="d-flex align-center px-4 py-2">
              <v-text-field
                v-model="SMS"
                class="pa-0 ma-0"
                clearable
                hide-details
              ></v-text-field>
              <v-btn color="primary" class="ml-3" icon small @click="search">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-tooltip bottom v-model="help">
                <template v-slot:activator="{ attrs }">
                  <v-btn
                    color="primary"
                    class="ml-2"
                    icon
                    small
                    v-bind="attrs"
                    @click="help = !help"
                  >
                    <v-icon>mdi-help-circle-outline</v-icon>
                  </v-btn>
                </template>
                <div style="width:200px">
                  由于腹膜透析基本居家操作，患者是治疗的主要实施者，因此学会处理一些常见问题对患者的治疗至关重要。
                </div>
              </v-tooltip>
            </div>
            <v-expansion-panels class="px-0" accordion>
              <v-expansion-panel v-for="(item, index) in table" :key="item.ID">
                <v-expansion-panel-header>
                  <div
                    class="primary--text details"
                    style="font-size: 15px;text-indent:-1.85em;padding-left:1.35em"
                  >
                    {{ index + 1 }}、{{ item.PNAME }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="details">
                  <div v-if="item.PPERFORMANCE" class="mb-3">
                    表现：{{ item.PPERFORMANCE }}
                  </div>
                  <div class="mb-3">原因：{{ item.PREASON }}</div>
                  <div>措施：{{ item.PMEASURES }}</div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <div color="red" class="mx-2 mt-1 mb-2" flat>
            <v-card
              class="border_1 d-flex justify-center"
              flat
              tile
              height="100"
              @click="addDialog = true"
            >
              <v-icon size="40" color="primary">
                mdi-plus
              </v-icon>
            </v-card>
            <v-card
              class="mt-4 pa-4 details"
              v-for="(item, index) in history"
              :key="index"
            >
              <div class="primary--text">问题：{{ item.PROBLEM }}</div>
              <div>
                提问时间：
                <span class="details_time">{{ item.CTIME }}</span>
              </div>
              <div>
                状态：{{ `${item.ISCOMPLETE === "1" ? "待回答" : "已回答"}` }}
              </div>
              <div
                class="border_2 px-4 py-2 mt-2"
                v-if="item.ISCOMPLETE === '2'"
              >
                <div>回答时间：{{ item.PACTIME }}</div>
                <div>医生姓名：{{ item.PRECORD }}</div>
                <div>答案：{{ item.PANSWER }}</div>
              </div>
            </v-card>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="addDialog">
      <v-card-text>
        <v-textarea
          v-model="question"
          outlined
          label="请输入你的问题"
          hide-details
        />
      </v-card-text>
      <v-card-actions class="pt-0 mt-0 pb-4">
        <v-spacer />
        <v-btn
          class="mr-2"
          color="primary"
          @click="addQuestion"
          :loading="loading"
        >
          提交
        </v-btn>
      </v-card-actions>
    </v-dialog>
    <PreCheck />
  </div>
</template>
<script>
import { mapState } from "vuex";
import PreCheck from "@/components/Local/PreCheck.vue";
export default {
  name: "Questions",
  components: { PreCheck },
  data: () => ({
    tab: 0,
    items: ["常见问题", "人工回复"],
    table: [],
    help: false,
    dialog: false,
    addDialog: false,
    SMS: undefined,
    loading: false,
    history: [],
    question: "",
  }),
  computed: {
    ...mapState(["openId", "currentUser"]),
  },
  created() {
    if (this.$route.query && this.$route.query.tab) {
      this.tab = this.$route.query.tab * 1;
    }
    this.postGet_Dialysisp();
    this.currentUser && this.postGet_SAutop();
  },
  methods: {
    search() {
      this.dialog = false;
      this.postGet_Dialysisp();
    },
    postGet_Dialysisp() {
      this.loading = true;
      this.$api
        .postGet_Dialysisp({
          SMS: this.SMS,
        })
        .then((res) => {
          let data = JSON.parse(res);
          if (data.code !== 0) {
            this.$notify.error({
              message: data.message,
            });
            return;
          } else {
            let table = JSON.parse(data.data || "[]");
            this.table = table;
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error,
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    postGet_SAutop() {
      this.loading = true;
      this.$api
        .postGet_SAutop({
          IDCARD: this.currentUser.IDCARD,
        })
        .then((res) => {
          let data = JSON.parse(res);
          if (data.code !== 0) {
            this.$notify.error({
              message: data.message,
            });
            return;
          } else {
            let history = JSON.parse(data.data || "[]");
            this.history = history;
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error,
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addQuestion() {
      if (this.question.length < 10) {
        this.$notify.warning({
          message: "问题描述过短！",
        });
        return;
      }
      this.loading = true;
      this.$api
        .postT_SAutop({
          PROBLEM: this.question,
          IDCARD: this.currentUser.IDCARD,
          PNAME: this.currentUser.PNAME,
          PSEX: this.currentUser.PSEX,
          PAGE: this.currentUser.PAGE,
        })
        .then((res) => {
          let data = JSON.parse(res);
          if (data.code !== 0) {
            this.$notify.error({
              message: data.message,
            });
            return;
          } else {
            this.addDialog = false;
            this.question = "";
            this.postGet_SAutop();
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error,
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
.details {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.14rem;
}
.details_time {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04rem;
}
.border_1 {
  border: 2px dashed #1976d2 !important;
}
.border_2 {
  border: 2px dashed #66cc99 !important;
}
</style>
